import * as React from "react";
import Footer1 from "../components/footer-1";
import Header1 from "../components/header";
import { isLoggedIn } from "../services/auth";
import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Alert, Snackbar } from "@mui/material"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../components/credit-checkout/checkout-form';
import { useApplicationContext } from "../../provider";
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntent, selectPaymentState } from "../sagas/payment/paymentSlice";
import { updatePaymentIntent } from "../sagas/credits/creditsSlice";

export default function Layout() {
    const dispatch = useDispatch();
    const { isCreatingPaymentIntentSuccess, isCreatingPaymentIntentFailed, CreatePaymentIntentResponse } = useSelector(selectPaymentState);

    const [snackSuccessOpen, setSnackSuccessOpen] = useState(false);
    const [snackFailedOpen, setSnackFailedOpen] = useState(false);
    const [message, setMessage] = useState();
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState();
    const [credits, setCredits] = useState();
    const subScription = [{price: 1, centsPerCredit: '.50', credits: 200}, {price: 5, centsPerCredit: '.25', credits: 2000}, {price: 10, centsPerCredit: '.20', credits: 5000}];
    const { setCreditCount } = useApplicationContext();
    const [stripePubkey, setStripePubkey] = useState();
    const [clientSecret, setClientSecret] = useState("");
    const [options, setOptions] = useState({})
    const [paymentIntentId, setPaymentIntentId] = useState();

    const handleClose = (event, reason) => {
        setSnackSuccessOpen(false);
        setSnackFailedOpen(false);
        setOpen(false)
    };

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/signin");
        }

        dispatch({
            type: createPaymentIntent.type,
            payload: { currency: "usd", type: 'credit' },
        });
    }, []);

    useEffect(() => {
        if (isCreatingPaymentIntentSuccess) {
            setClientSecret(CreatePaymentIntentResponse.paymentIntent.client_secret);
            setOptions({
                clientSecret: CreatePaymentIntentResponse.paymentIntent.client_secret,
                appearance: {},
            });
            setPaymentIntentId(CreatePaymentIntentResponse.paymentIntent.id);
            setStripePubkey(CreatePaymentIntentResponse.key);
        } else if (isCreatingPaymentIntentFailed) {
            console.log("Unable to create a payment intent.")
        }
    }, [isCreatingPaymentIntentSuccess, isCreatingPaymentIntentFailed, CreatePaymentIntentResponse])

    const goToCheckout = async (credits, price) => {
        dispatch({
            type: updatePaymentIntent.type,
            payload: { item: {price: price, credits: credits} , currency: "usd", intentID: paymentIntentId },
        });

        setCredits(credits);
        setPrice(price);
        setOpen(true)
    }

    return (
        (clientSecret !== "" && stripePubkey) &&
        <Elements stripe={loadStripe(stripePubkey)} options={options}>
            <>
                <div className="container-main" id="page">
                    {/* Header */}
                    <Header1></Header1>
                    <Snackbar
                        open={snackSuccessOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert severity="success">
                            {message}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={snackFailedOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert severity="error">
                            {message}
                        </Alert>
                    </Snackbar>
                    {/* Content - Main */}
                    <main className="content-main">
                        <div className="pricing-tables">
                            <div className="container">
                                <h1>Pricing Options</h1>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="box">
                                            <h3>{subScription[0].credits.toLocaleString()} <br /> credits</h3>
                                            <div className="price-out">
                                                <div className="price">${subScription[0].price.toFixed(2)}</div>
                                                <p>{subScription[0].centsPerCredit} Cents <br /> per credit</p>
                                            </div>
                                            <div className="btn-out"><a href="#" className="btn" onClick={() => goToCheckout(subScription[0].credits, subScription[0].price)}>Buy Now</a></div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box selected">
                                            <h3>{subScription[1].credits.toLocaleString()} <br /> credits </h3>
                                            <div className="price-out">
                                                <div className="price">${subScription[1].price.toFixed(2)}</div>
                                                <p>{subScription[1].centsPerCredit} Cents <br /> per credit</p>
                                            </div>
                                            <div className="btn-out"><a href="#" className="btn" onClick={() => goToCheckout(subScription[1].credits, subScription[1].price)}>Buy Now</a></div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="box">
                                            <h3>{subScription[2].credits.toLocaleString()} <br /> credits</h3>
                                            <div className="price-out">
                                                <div className="price">${subScription[2].price.toFixed(2)}</div>
                                                <p>{subScription[2].centsPerCredit} Cent <br /> per credit</p>
                                            </div>
                                            <div className="btn-out"><a href="#" className="btn" onClick={() => goToCheckout(subScription[2].credits, subScription[2].price)}>Buy Now</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer1></Footer1>
                </div>
            </>
            <CheckoutForm open={open} handleClose={handleClose} 
                setSnackSuccessOpen={setSnackSuccessOpen}
                setSnackFailedOpen={setSnackFailedOpen}
                setMessage={setMessage}
                price={price} credits={credits}
                setCreditCount={setCreditCount}
                setOpen={setOpen}/>
        </Elements>
    )
}