import React, {useState, useEffect} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { navigate } from 'gatsby';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Box } from '@mui/system';

const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");

export default function CheckoutForm({open, handleClose, setSnackSuccessOpen, setSnackFailedOpen, setMessage, price, credits, setCreditCount}){
  const stripe = useStripe();
  const elements = useElements();
  const hostUrl = window.location.protocol + '//' + window.location.host;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${hostUrl}/pricing-options?credits=${credits}`,
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setIsLoading(false);
  };

  const calculateAmout =(price)=>{
    const amount = price * 0.029
    return Math.abs(amount + price + 0.30).toFixed(2)
  }

  return (
    <>
      <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{
              textAlign: 'center',
              color: 'black',
              backgroundColor: 'white'
            }}>
              {"Checkout"}
          </DialogTitle>
          <DialogContent
            sx={{
                backgroundColor: '#f6f5ff'
            }}
          >
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  m: 'auto',
                  width: '100%',
                  textAlign: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  paddingTop: '34px',
                  fontSize:'20px'
              }}>
                  <span style={{marginRight: '5%'}}>Price: ${ calculateAmout(price)}</span>
                  <span>Credits: {credits}</span>
              </Box>

          </DialogContent>
          <Box sx={{
              color: 'white',
              backgroundColor: '#f6f5ff',
              padding: "16px 16px"
          }}>
            <form onSubmit={handleSubmit}>
              <PaymentElement />
              <div className='checkout-btn-container'>
                {isLoading ? <div className="spinner" id="spinner"></div> : <button className='checkout-btn btn' disabled={!stripe}>Submit</button>}
              </div>
              {/* Show error message to your customers */}
              {errorMessage && <div>{errorMessage}</div>}
            </form>
          </Box>
      </Dialog>
      <PaymentStatus
          setSnackSuccessOpen={setSnackSuccessOpen}
          setSnackFailedOpen={setSnackFailedOpen}
          setMessage={setMessage}
          setCreditCount={setCreditCount}
        />
    </>
  )
};

const PaymentStatus = ({setSnackSuccessOpen, setSnackFailedOpen, setMessage, setCreditCount}) => {
  const stripe = useStripe();
  // const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    const selectedCredits = new URLSearchParams(window.location.search).get('credits');

    // Retrieve the PaymentIntent
    if(clientSecret) {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({paymentIntent}) => {
          // Inspect the PaymentIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (paymentIntent.status) {
            case 'succeeded':
              let cur_credits = currentUser.credits;
              cur_credits = parseFloat(selectedCredits) + parseFloat(cur_credits);
              setCreditCount(cur_credits);
              currentUser.credits = cur_credits;
              localStorage.setItem("currentUser", JSON.stringify(currentUser));
              setMessage("Success! Payment received.")
              setSnackSuccessOpen(true)
              break;
  
            case 'processing':
              setMessage("Payment processing. We'll update you when payment is received.");
              setSnackSuccessOpen(true)
              break;
  
            case 'requires_payment_method':
              // Redirect your user back to your payment page to attempt collecting
              // payment again
              setMessage('Payment failed. Please try another payment method.');
              setSnackFailedOpen(true);
              break;
  
            default:
              setMessage('Something went wrong.');
              setSnackFailedOpen(true);
              break;
          }
          navigate('/pricing-options')
        });
    }
  }, [stripe]);

  return (
    <></>
  );
};